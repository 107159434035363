import React,{useRef,useState,useEffect,useContext} from 'react'
import { useLocation ,useNavigate} from 'react-router-dom';
import axios from 'axios'




export default function CheckoutSuccess() {
  const search = useLocation().search
  const navigate = useNavigate()

  useEffect(()=>{
    const recObj={...JSON.parse(sessionStorage.getItem('record')),SessionID:new URLSearchParams(search).get('session_id')}
    axios.post(process.env.REACT_APP_API_SERVER+'manager/eshopsuccess',recObj).then(res=>{
        if (res.data.err==0){
          sessionStorage.setItem('Email',recObj.Email)
          sessionStorage.setItem('Password',recObj.Password)
          navigate('/login')
        }else{
          alert('Error')
        }
    })
  },[])

  return (
    <div></div>
  )
}
