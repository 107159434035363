import React,{useContext, useEffect,useState,useRef} from 'react'
import axios from 'axios'
import {Link,NavLink,useNavigate,useParams } from 'react-router-dom'
import {UserContext} from '../context/UserContext'
import {Alert,Container,Form,Button,Card,Navbar,NavDropdown,Nav,Table,Modal} from 'react-bootstrap';
import logo from '../logo.png'
import { MultiSelect } from "react-multi-select-component";
import moment from 'moment';
import DataTable from 'react-data-table-component';
import exportFromJSON from 'export-from-json' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faShop,faPlus, faShoppingBasket,faShoppingCart } from '@fortawesome/free-solid-svg-icons'

export default function Lists() {
    const navigate=useNavigate ()
    const [lists,setLists]=useState([])
    const [isLoading,setIsLoading]=useState(false)

    const columns = [
      {
          name: '#',
          selector: (row,index) =>  index+1,
          sortable: true,
          maxWidth:'5px'
      },
      {
        name: '',
        selector: row => <Button variant="secondary" onClick={()=>navigate('/list/'+row.UUID)}><FontAwesomeIcon icon={faEdit}/></Button>,
        sortable: true,
        maxWidth:'5px'
    },      
      {
          name: 'Name',
          selector: (row,index) =>  row.listName,
          sortable: true
      },
      {
        name: 'Date Created',
        selector: (row,index) =>  row.DateCreated.split('T')[0],
        sortable: true
      }
    ]
    useEffect(()=>{
      loadLists()
    },[])

    const loadLists=()=>{
      setIsLoading(true)
      axios.get(process.env.REACT_APP_API_SERVER+'manager/listscriteria/lists/'+sessionStorage.getItem('userToken'),{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
              }            
          })
        .then(res=>{
          if (res.data.err==0){
            //console.log(res.data.results)
            setLists(res.data.results)
          }else{
            alert('An error has occured')
          }
          
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        }) 
    }

  return (
    <Container fluid>
        <br/>
    <Card>

      <Card.Body>
        <Card.Title>
            <table width="100%">
                <tr>
                    <td width="20"></td>
                    <td>
                        Players' Lists
                    </td>
                    <td align="right">
                        <Button onClick={()=>navigate('/list')} variant="success" style={{backgroundColor:'#7367f0',fontFamily:'tahoma'}}>+ Create New List</Button>
                    </td>
                    <td width="20"></td>
                </tr>
            </table>

        </Card.Title>

        <DataTable
          columns={columns}
          data={lists}
          highlightOnHover
          //expandableRows
          //expandableRowsComponent={ExpandedComponent}
          //pagination
        /> 

      </Card.Body>
    </Card>
    </Container>
  )
}
