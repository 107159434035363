import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams ,Link,useNavigate} from 'react-router-dom'
import axios from 'axios'
import {Alert,Container,Row,Col,Form,Button,Table,Card} from 'react-bootstrap'
import Logo from '../logo.png'
import { CircleFlag } from 'react-circle-flags'
import loading from './loading.gif'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FaArrowRight} from 'react-icons/fa';

export default function DashboardChallenges() {

    const [data,setData]=useState([])
    const [isLoading,setIsLoading]=useState(true)
    const [chartData,setChartData]=useState([])
    const navigate=useNavigate()


    useEffect(()=>{
        loadData()
    },[])

    const loadData=()=>{
        //setIsLoading(true)
        const obj={
            SportID:sessionStorage.getItem('SportID'),
            Countries:JSON.parse(sessionStorage.getItem('appCountries'))
        }
        axios.post(process.env.REACT_APP_API_SERVER+'manager/dashboard/challenges',obj,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
              }            
          })
        .then(res=>{
            
            setData(res.data)
            const xdata=[]
            res.data.forEach((item,index)=>{
                const obj={
                    name:item.Title,
                    value:item.CompletedPercentage,
                    index:index+1
                }
                xdata.push(obj)
            })
            setChartData([...xdata])
            setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
    
        }) 
    }  

    const dataList=data.map(item=>{
        return(
            <tr>
                <td valign="middle">
                <div style={{ width: 40, height: 40 }}>
                <CircularProgressbar value={item.CompletedPercentage} text={`${item.CompletedPercentage}%`} />
                </div>
                </td>

                <td width="20"></td>

                <td valign="middle">{item.Title}<br/><font color="gray"><i>{item.c} tasks</i></font></td>
                <td width="20"></td>
                <td width="20">{item.c>0 ? <Button variant='light' onClick={()=>navigate('/challenges/'+item.ChallengeUUID)}><FaArrowRight/></Button> : ''}</td>

            </tr>
        )
    })

  return (
    <Card style={{ width: '100%',boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',border: '1px solid #f0f0f0' }}>
    <Card.Body>
      <Card.Title>Challenges Progress</Card.Title>
      <Card.Text>
      {!isLoading &&
        <Table width="100%" borderless>
            <thead>
                
                <tr style={{borderBottom:'1px solid #000',borderTop:'1px solid #000'}}>
                <td style={{fontSize:'0.8125rem',letterSpacing:'1px'}}>%</td>
                <td ></td>
                <td style={{fontSize:'0.8125rem',letterSpacing:'1px'}}>CHALLENGE TITLE</td>
                <td ></td>
                <td ></td>
                </tr>
                
            </thead>
            <tbody>
                {dataList}
            </tbody>
        </Table>
        }

        {isLoading &&
          <p align="center"><img src={loading} width="200"/></p>
        
        }
      </Card.Text>
    </Card.Body>
  </Card>
  )
}
