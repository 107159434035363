import React,{useContext, useEffect,useState,useRef} from 'react'
import axios from 'axios'
import {Link,NavLink,useNavigate,useParams } from 'react-router-dom'
import {UserContext} from '../context/UserContext'
import {Alert,Container,Form,Button,Card,Navbar,NavDropdown,Nav,Table,Modal,Row,Col,} from 'react-bootstrap';
import logo from '../logo.png'
import { MultiSelect } from "react-multi-select-component";
import moment from 'moment';
import DataTable from 'react-data-table-component';
import exportFromJSON from 'export-from-json' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faBasketShopping,faEdit,faShop,faPlus, faShoppingBasket,faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import UserDetails from './UserDetails';
import UserActivities from './UserActivities';
import UserPosts from './UserPosts';
import UserStats from './UserStats';

export default function User() {
    const {uuid}=useParams()
  return (
    <Container fluid>
      <Row style={{padding:5}}>
        <Col md={5} style={{padding:10}}>
        <UserDetails uuid={uuid}/>
        </Col>  

        <Col md={7} style={{padding:10}}>
          <Row style={{marginBottom:20}}>
              <Col><UserStats uuid={uuid}/></Col>
          </Row>
          
          <Row>
              <Col><UserActivities uuid={uuid}/></Col>
          </Row>
        
        </Col>
      </Row>
      <hr/>
      <div style={{fontFamily:'Arial, Helvetica, sans-serif',color:'#6f6b7d',fontSize:'16px',fontWeight:'bold'}}>
            User Library
            </div>
      <Row>
        <UserPosts  uuid={uuid}/>
      </Row>
    </Container>
  )
}
