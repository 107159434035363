import React,{useContext, useEffect} from 'react'
import axios from 'axios'
import {Route,Navigate,useParams} from 'react-router-dom'
import {UserContext} from '../context/UserContext'
//import Header from './components/protected/Header'
import {Container,Row,Col} from 'react-bootstrap'
import DashboardNav from './DashboardNav'
// import DashboardFooter from './DashboardFooter'
import SidebarMenu from 'react-bootstrap-sidebar-menu'


export default function ProtectedRoute(params) {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {setToken}=useContext(UserContext)

    useEffect(()=>{
        if (isAuth || (sessionStorage.getItem("isAuth")==1)){
            setIsAuth(true)
        }
    },[])

    return (
       (isAuth || (sessionStorage.getItem("isAuth")==1)) ? <><Container fluid><DashboardNav/><params.Component/></Container></> : <Navigate to='/login'/>
    // (isAuth || (sessionStorage.getItem("isAuth")==1)) ? <><Container fluid><Row><Col xs={2}><DashboardNav /></Col><Col xs={10}><params.Component/></Col></Row></Container></> : <Navigate to='/login'/>
    )
}

