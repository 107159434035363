import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import {Alert,Container,Form,Button,Card,Navbar,NavDropdown,Nav,Table,Modal,Row,Col,Badge} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faVideo } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';

export default function Billings() {
    const [data,setData]=useState([])
    const [isLoading,setIsLoading]=useState(false)

    useEffect(()=>{
        loadData()
    },[])


    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'manager/customersettings/stripereceipts/'+sessionStorage.getItem('userToken'),{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
              }            
          })
        .then(res=>{
    
    
            console.log(res.data)
            setData(res.data.results)
    
           setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
    
        })          
    }

  return (
    <div>Billings</div>
  )
}
