import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams,Link } from 'react-router-dom'
import axios from 'axios'
import {Alert,Container,Form,Button,Card,Navbar,NavDropdown,Nav,Table,Modal,Row,Col,Badge} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faVideo } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import loading from './loading.gif'
import VideoPlayer from 'react-video-js-player';

export default function Challenges() {
    const {challengeuuid}=useParams()
    const [data,setData]=useState([])
    const [isLoading,setIsLoading]=useState(true)
    const [show,setShow]=useState(false)
    const [fileID,setFileID]=useState('')
    const [title,setTitle]=useState('')
    const [descr,setDescr]=useState('')

  

    const columns = [

        {
            name: '',
            selector: row => <Button variant="success" onClick={()=>handleShow(row.FileID,row.Title,row.Descr)}><FontAwesomeIcon icon={faVideo}/></Button>,
            sortable: true,
            maxWidth:'5px'
        }, 

      {
        name: '',
        selector: row => {
            return(
            <Link to={`/user/${row.UserUUID}`}>
            <div style={{
                width: '50px',  /* Adjust the size as needed */
                height: '50px', /* Adjust the size as needed */
                borderRadius: '50%',
                borderColor:'gray',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundImage:`url(${process.env.REACT_APP_API_SERVER}files/${row.UserUUID}/123)`                  
            }}/>
            </Link>                
            )
        },
        sortable: true,
        maxWidth:'50px'
    }, 
    {
        name: 'Points',
        selector: (row,index) =>  row.Points,
        sortable: true,
        maxWidth:'50px'
    },
        {
            name: 'User',
            selector: (row,index) =>  row.FirstName+' '+row.LastName,
            sortable: true
        },

    
      ]

    useEffect(()=>{
        loadData()    
    },[])

    const handleClose=()=>{
  
        setShow(false)
      }

    const handleShow=(fid,t,d)=>{
        // setTitle(t)
        // setDescr(d)
        setFileID(fid)
        setShow(true)
    }

    const loadData=()=>{
        //setIsLoading(true)
        const obj={
            ChallengeUUID:challengeuuid,
            Countries:JSON.parse(sessionStorage.getItem('appCountries'))
        }
        axios.post(process.env.REACT_APP_API_SERVER+'manager/dashboard/challengeusers',obj,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
              }            
          })
        .then(res=>{
            //console.log(res.data)
            setData(res.data)
            setTitle(res.data[0].Title)
            setDescr(res.data[0].Descr)
            setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
    
        }) 
    }  

  return (
    <Container fluid>
        {!isLoading &&
        <>
        <table width="100%">
            <tr>
                <td>
                    <img src={`${process.env.REACT_APP_API_SERVER}/admin/files/${data[0]?.ChallengeUUID}/123`} width="100"/>
                </td>
                <td width="50"></td>
                <td valign="middle">
                    <h4>{data[0]?.Title}</h4>
                    <br/>
                    <div
                    dangerouslySetInnerHTML={{ __html: data[0]?.Descr.replace(/\n/g, '<br>') }}
                    />
                </td>
            </tr>
        </table>
        <br/><br/>
        <DataTable
              columns={columns}
              data={data}
              highlightOnHover
              //expandableRows
              //expandableRowsComponent={ExpandedComponent}
              //pagination
            /> 


            <Modal show={show} onHide={handleClose} backdrop="static" size="lg">
                <Modal.Header closeButton>
                <Modal.Title>
                  {title}
                </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                  <p align="center">
                    
                    <div
                    dangerouslySetInnerHTML={{ __html: descr.replace(/\n/g, '<br>') }}
                    />
                    <hr/>



            <VideoPlayer
                    controls={true}
                    src={`${process.env.REACT_APP_VIDEO_BASE_URL}${fileID}.smil/playlist.m3u8`}
                    poster={process.env.REACT_APP_VIDEOSERVER_API+''+fileID+'.jpg'}
                    width="720"
                    height="420"
                    // onReady={this.onPlayerReady.bind(this)}
                    // onPlay={this.onVideoPlay.bind(this)}
                    // onPause={this.onVideoPause.bind(this)}
                    // onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                    // onSeeking={this.onVideoSeeking.bind(this)}
                    // onSeeked={this.onVideoSeeked.bind(this)}
                    // onEnd={this.onVideoEnd.bind(this)}
                />

                  </p>
                </Modal.Body>

            </Modal>
            </>
        }

        {isLoading &&
        <p align="center"><img src={loading} width="200"/></p>
        }    
    </Container>
  )
}
