import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import {Alert,Container,Row,Col,Form,Button,Table,Card} from 'react-bootstrap'
import Logo from '../logo.png'
import PopularPlayers from './PopularPlayers'
import PopularCountries from './PopularCountries'
import DashboardChallenges from './DashboardChallenges'
import DashboardSearchedPositions from './DashboardSearchedPositions'
import DashboardGeneralStats from './DashboardGeneralStats'

export default function Dashboard() {
  useEffect(()=>{
   // console.log(sessionStorage.getItem('appCountries'))
  },[])
  return (
    <Container fluid>
      <Row>  
        <Col md={12} style={{padding:10}}>
          <DashboardGeneralStats/>
        </Col>
      </Row>
      <Row>  
        <Col md={12} style={{padding:10}}>
          <DashboardSearchedPositions/>
        </Col>


      </Row>
      <Row>  
        <Col md={4} style={{padding:10}}>
        <DashboardChallenges/>
        </Col>
        <Col md={4} style={{padding:10}}>
        <PopularPlayers/>
        </Col>
        <Col md={4} style={{padding:10}}>
        <PopularCountries/>
        </Col>
      </Row>

    </Container>
  )
}
