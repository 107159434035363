import React,{useState,useEffect,useContext,createContext} from 'react'
import {Route,Routes,Navigate,useNavigate,BrowserRouter,Switch,HashRouter} from 'react-router-dom'
import {UserContext} from './context/UserContext'

import Checkout from './Checkout'
import CheckoutSuccess from './CheckoutSuccess'
import ProtectedRoute from './protected/ProtectedRoute'
import Dashboard from './protected/Dashboard'
import Lists from './protected/Lists'
import CreateList from './protected/CreateList'
import User from './protected/User'
import Billings  from './protected/Billings'
import ChatRoom from './protected/ChatRoom'
import Challenges from './protected/Challenges'
import FavouritePlayers from './protected/FavouritePlayers'
import Login from './Login'
import PlayersList from './protected/PlayersList'
import Profile from './protected/Profile'

import io from 'socket.io-client'


export default function App() {
  const [isAuth,setIsAuth]=useState(false)
  const [userToken,setUserToken]=useState(null)
  const [apiToken,setApiToken]=useState(null)
  const [userStatus,setUserStatus]=useState(0)
  const [userName,setUserName]=useState(null)
  const [socket,setSocket]=useState(io(process.env.REACT_APP_SOCKET,{path: "/api/socket.io"},{transports: [ "websocket", "polling" ]}))
  const [usrPhoto,setUsrPhoto]=useState('')
 //const [socket,setSocket]=useState(null)

 const loadUnreadMessages=()=>{

 }

  return (
    <UserContext.Provider value={{usrPhoto,setUsrPhoto,isAuth,setIsAuth,userToken,setUserToken,apiToken,setApiToken,userName,setUserName,socket,setSocket,loadUnreadMessages}}>
    <HashRouter basename={'/'}>
    <Routes>
        <Route exact path="/" element={<Login/>}/>
        <Route exact path="/login" element={<Login/>}/>
        <Route exact path="/checkout/:uuid" element={<Checkout/>}/>
        <Route exact path="/purchasesuccess" element={<CheckoutSuccess/>}/>
        

        <Route exact path="/dashboard" element={<ProtectedRoute Component={Dashboard}/>}/> 
        <Route exact path="/lists" element={<ProtectedRoute Component={Lists}/>}/> 
        <Route exact path="/list" element={<ProtectedRoute Component={CreateList}/>}/> 
        <Route exact path="/list/:listID" element={<ProtectedRoute Component={CreateList}/>}/> 
        <Route exact path="/user/:uuid" element={<ProtectedRoute Component={User}/>}/> 
        <Route exact path="/challenges/:challengeuuid" element={<ProtectedRoute Component={Challenges}/>}/> 
        <Route exact path="/billings" element={<ProtectedRoute Component={Billings}/>}/> 
        <Route exact path="/favouriteplayers" element={<ProtectedRoute Component={FavouritePlayers}/>}/> 
        <Route exact path="/playerslist" element={<ProtectedRoute Component={PlayersList}/>}/> 
        <Route exact path="/profile" element={<ProtectedRoute Component={Profile}/>}/> 
        <Route exact path="/chatroom/:roomUUID" element={<ProtectedRoute Component={ChatRoom}/>}/> 
      </Routes>
    </HashRouter>
    </UserContext.Provider>
  )
}
