import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams,Link } from 'react-router-dom'
import axios from 'axios'
import {Alert,Container,Form,Button,Card,Navbar,NavDropdown,Nav,Table,Modal,Row,Col,Badge} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faVideo} from '@fortawesome/free-solid-svg-icons'
import { FaHome, FaLink, FaUsers,FaList,FaBasketballBall,FaMobile} from 'react-icons/fa';
import moment from 'moment';
import loading from './loading.gif'



export default function DashboardGeneralStats() {
  const [wallOfFame,setWallOfFame]=useState([])
  const [data,setData]=useState([])
  const [activeSessions,setActiveSessions]=useState(0)
  const [activeSessionsEngaged,setActiveEngaged]=useState(0)
  const [activeSessionsChallenges,setActiveSessionsChallenges]=useState(0)
  const [dataTot,setDataTot]=useState(0)
  const [isLoading,setIsLoading]=useState(true)

  useEffect(()=>{
    loadData()
    loadDataChallnges()
    loadDataEngaged()
},[])

const loadData=()=>{
  const obj={
    SportID:sessionStorage.getItem('SportID'),
    Countries:JSON.parse(sessionStorage.getItem('appCountries'))
}

//console.log(obj)
    setIsLoading(true)
    axios.post(process.env.REACT_APP_API_SERVER+'manager/dashboard/dashboardgenstatsactivesessions',obj,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
          }            
      })
    .then(res=>{
      setActiveSessions(res.data.results[0].ActiveSessions)
        setIsLoading(false)
    }).catch(err=>{
        setIsLoading(false)

    }) 
}  

const loadDataChallnges=()=>{
  const obj={
    SportID:sessionStorage.getItem('SportID'),
    Countries:JSON.parse(sessionStorage.getItem('appCountries'))
}

//console.log(obj)
    setIsLoading(true)
    axios.post(process.env.REACT_APP_API_SERVER+'manager/dashboard/dashboardgenstatschellenges',obj,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
          }            
      })
    .then(res=>{
      setActiveSessionsChallenges(res.data.results[0].ActiveSessions)
        setIsLoading(false)
    }).catch(err=>{
        setIsLoading(false)

    }) 
}  


const loadDataEngaged=()=>{
  const obj={
    SportID:sessionStorage.getItem('SportID'),
    Countries:JSON.parse(sessionStorage.getItem('appCountries'))
}

//console.log(obj)
    setIsLoading(true)
    axios.post(process.env.REACT_APP_API_SERVER+'manager/dashboard/dashboardgenstatsengaged',obj,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
          }            
      })
    .then(res=>{
      setActiveEngaged(res.data.results[0].ActiveSessions)
        setIsLoading(false)
    }).catch(err=>{
        setIsLoading(false)

    }) 
}  

  return (
    <Card style={{ width: '100%' ,boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',border: '1px solid #f0f0f0'}}>
    <Card.Body>
      <Card.Title>Welcome {sessionStorage.getItem('userName')}<hr/></Card.Title>
      <Card.Text>
        {!isLoading &&
            <Row>
                <Col md={4}>
                  <Card>
                      <Card.Body>
                          <Card.Title><FaUsers/> Active Sessions: {activeSessions}</Card.Title>
                          <Card.Text></Card.Text>
                      </Card.Body>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                      <Card.Body>
                          <Card.Title><FaMobile/> Engaged Users: {activeSessionsEngaged}</Card.Title>
                          <Card.Text></Card.Text>
                      </Card.Body>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                      <Card.Body>
                          <Card.Title><FaBasketballBall/> Challenge Partcipants: {activeSessionsChallenges}</Card.Title>
                          <Card.Text></Card.Text>
                      </Card.Body>
                  </Card>
                </Col>

            </Row>

        }
        {isLoading &&
          <p align="center"><img src={loading} width="200"/></p>
        
        }
      </Card.Text>
    </Card.Body>
  </Card>
  )
}
