import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams,Link } from 'react-router-dom'
import axios from 'axios'
import {Alert,Container,Form,Button,Card,Navbar,NavDropdown,Nav,Table,Modal,Row,Col,Badge} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faVideo } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import loading from './loading.gif'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList,ResponsiveContainer } from 'recharts';


export default function DashboardSearchedPositions() {
    const [wallOfFame,setWallOfFame]=useState([])
    const [data,setData]=useState([])
    const [dataTot,setDataTot]=useState(0)
    const [isLoading,setIsLoading]=useState(true)

    const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FF5733'];



    useEffect(()=>{
        loadData()
    },[])

    const loadData=()=>{
        //setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'manager/dashboard/searchedpositions/'+sessionStorage.getItem('userToken'),{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
              }            
          })
        .then(res=>{
            //console.log(res.data)
            setIsLoading(false)
            setData(res.data.results)
        }).catch(err=>{
            setIsLoading(false)
    
        }) 
      }  

      function getRandomEntry(arr) {
        const randomIndex = Math.floor(Math.random() * arr.length);
        return arr[randomIndex];
      }

  return (
    <Card style={{ width: '100%' ,boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',border: '1px solid #f0f0f0'}}>
    <Card.Body>
      <Card.Title>Positions you searched more</Card.Title>
      <Card.Text>
        {!isLoading &&
        <div style={{ width: '100%', height: 400 }}>
             <ResponsiveContainer width="100%" height="100%">
            <BarChart

                data={data}
                layout="vertical"
                margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" domain={[0,100]} tickFormatter={(value) => `${value}%`}/>
                <YAxis type="category"  dataKey="label" hide={true}/>

                <Bar dataKey="value" fill="#82ca9d" barSize={25}>
                {/* LabelList is used to add text inside the bars */}
                <LabelList dataKey="label" position="inside" fill="white" />
                </Bar>
            </BarChart>
            </ResponsiveContainer>
            </div>

        }
        {isLoading &&
          <p align="center"><img src={loading} width="200"/></p>
        
        }
      </Card.Text>
    </Card.Body>
  </Card>
  )
}
