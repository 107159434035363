import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import {Alert,Container,Row,Col,Form,Button,Table,Card} from 'react-bootstrap'
import Logo from './logo.png'
import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'
import { continents, countries, languages } from 'countries-list'
import { MultiSelect } from "react-multi-select-component";

export default function Checkout() {
  const [selected, setSelected] = useState([]);

  const {uuid}=useParams()
  const [data,setData]=useState({})
  const [isLoading,setIsLoading]=useState(true)
  const [err,setErr]=useState(false)
  const [icon, setIcon] = useState(eyeOff);
  const [type, setType] = useState('password');
  const [appCountries,setAppCountries]=useState([])
  const [record,setRecord]=useState({
    FullName:'',
    VatNumber:'',
    Password:'',
    Email:'',
    Country:'',
    Telephone:'',
    telPrefix:''

  })

  useEffect(()=>{
    loadData()

  },[])

  const loadData=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'manager/eshopproducts/'+uuid)
    .then(res=>{
      if (res.data.err==0){
        if (res.data.results.length==1){
          setErr(false)
          setData(res.data.results[0])
          loadCountries()
          if (sessionStorage.getItem('record')){
            setRecord(JSON.parse(sessionStorage.getItem('record')))
            if(JSON.parse(sessionStorage.getItem('record')).appCountries){
              setSelected(JSON.parse(sessionStorage.getItem('record')).appCountries)
            }
          }
        }else{
          setErr(true)
        }
      }else{
        setErr(true)
      }
      setIsLoading(false)
    }).catch(xerr=>{
        setErr(true)
        setIsLoading(false)
    })          
}

const loadCountries=()=>{
  setIsLoading(true)
  axios.get(process.env.REACT_APP_API_SERVER+'manager/eshopcountries')
  .then(res=>{
    if (res.data.err==0){

        setAppCountries(res.data.results)

    }else{
      setErr(true)
    }
    setIsLoading(false)
  }).catch(xerr=>{
      setErr(true)
      setIsLoading(false)
  })          
}

const handleToggle = () => {
  if (type==='password'){
     setIcon(eye);
     setType('text')
  } else {
     setIcon(eyeOff)
     setType('password')
  }
}

const countriesList=Object.entries(countries).sort((a,b)=>{
  if (a[1].name < b[1].name) {
      return -1;
  }
  if (a[1].name > b[1].name) {
      return 1;
  }
  return 0;       
}).map((item,index)=>{
 // console.log(item)
  return(
      <option key={'country'+index} value={item[1].name}>{item[1].name}</option>

  )
})


const appCountriesList=appCountries.map((item,index)=>{

  return(

      {label:item.Country, value:item.CountryID}
  )
})

const register=(e)=>{
  e.preventDefault()
  //console.log(selected)
  axios.get(process.env.REACT_APP_API_SERVER+'manager/eshopproducts/checkemail/'+record.Email).then(xres=>{
    if (xres.data.err==0){
      axios.get(process.env.REACT_APP_API_SERVER+'manager/eshopproducts/paymentlink/'+uuid)
      .then(res=>{
          if (res.data.err==0){
            if (data.NumberOfCountries>0){
              var regObj={...record,LicenceDurationMonths:data.LicenceDurationMonths,appCountries:selected}
            }else{
              var regObj={...record,appCountries:appCountriesList}
            }
            
            //console.log(regObj)
            sessionStorage.setItem('record',JSON.stringify(regObj))
            window.location.href=res.data.url
          }else{
  
              alert('ERROR: '+res.data.msg.code) 
          }
      }).catch(err=>{
  
          console.log(err)
      })   
    }else{
      alert('Email exists.')
    }
  })
       

}

const selectCountry=(countryName)=>{
  
  try{
    var telPrefix=Object.entries(countries).filter(item=>item[1].name==countryName)[0][1].phone[0]
  }catch(err){
    var telPrefix=''
  }
  setRecord({...record,Country:countryName,telPrefix:telPrefix})

}

useEffect(()=>{
  
  if (selected.length>data.NumberOfCountries){
    var sArr=selected
    sArr.pop()
    setSelected([...sArr])
  }
},[selected])

// const selectAppCountry=(x)=>{
//   setSelected;
//   console.log(x)
// }

  return (
    <Container>
      <br/>{err}
      {err &&
        <Alert variant="danger">An error has Occured</Alert>
      
      }

      {!err &&
        
        <>
          {isLoading &&
            <Alert variant="warning">Loading, please wait</Alert>
          }
        
          {!isLoading &&
            <>
              <Alert variant="success">
                <table width="100%">
                  <tr>
                      <td width="120" align="center"><img src={Logo} height="100"/></td>
                      <td width="20"></td>
                      <td valign="middle" align="center"><h3>{data.Title}<br/>{data.SportName}</h3></td>
                      <td width="140"></td>
                  </tr>
                </table>
                
                
                </Alert>

              <div
                dangerouslySetInnerHTML={{__html: data.Descr}}
              />


            <Card >
                <Card.Body>
                  <Card.Title>Registration Details<hr/></Card.Title>

                  <Card.Text>
                  <Form onSubmit={register}>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control type="text" maxLength={100} value={record.FullName} onChange={(e)=>setRecord({...record,FullName:e.target.value})}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>VAT Number</Form.Label>
                      <Form.Control type="text" maxLength={50} value={record.VatNumber} onChange={(e)=>setRecord({...record,VatNumber:e.target.value})}/>
                    </Form.Group>

                        <Form.Group>
                            <Form.Label>Country</Form.Label>  
                            <Form.Control required as="select" value={record.Country} onChange={(e)=>selectCountry(e.target.value)}>
                                
                                <option value=''>[Select Country]</option>
                                {countriesList}
                            </Form.Control>
                        </Form.Group>  
                      <br/>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control type="email" maxLength={100} value={record.Email} onChange={(e)=>setRecord({...record,Email:e.target.value})}/>

                    </Form.Group>
                 
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Telephone</Form.Label>
                      <Form.Control type="text"maxLength={50}  value={record.Telephone} onChange={(e)=>setRecord({...record,Telephone:e.target.value})}/>
                    </Form.Group>

              



                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password (6-12 characters)</Form.Label>
                      <table width="100%">
                        <tr>
                          <td><Form.Control type={type}  minLength={6} maxLength={12} value={record.Password} onChange={(e)=>setRecord({...record,Password:e.target.value})}/></td>
                          <td width="30">
                          <span class="flex justify-around items-center" onClick={handleToggle}>
                            <Icon class="absolute mr-10" icon={icon} size={25}/>
                        </span>

                          </td>
                        </tr>
                      </table>
                      

                    </Form.Group>

                    {data.NumberOfCountries>0 &&
                    <Form.Group className="mb-3">
                      <Form.Label>Access to users' countries <i>(select {data.NumberOfCountries} countries)</i></Form.Label>
                      <MultiSelect
                          hasSelectAll={false}
                          options={appCountriesList}
                          value={selected}
                          onChange={setSelected}
                          labelledBy="Select"

                      />
                  </Form.Group>
                    }

                    <p align="center">
                    <Button variant="success" type="submit">
                      <b>Proceed to payment via Stripe: {data.Price}€</b>
                    </Button>
                    </p>
                  </Form>


                  </Card.Text>

                </Card.Body>
              </Card>

            </>
          }
        </>
      
      }
    </Container>

  )
}
