import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import {Alert,Container,Form,Button,Card,Navbar,NavDropdown,Nav,Table,Modal,Row,Col,Badge} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faVideo } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import VideoPlayer from 'react-video-js-player';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList,ResponsiveContainer } from 'recharts';

export default function UserStats(params) {
    const [stats,setStats]=useState([])
    const [isLoading,setIsLoading]=useState(false)

    useEffect(()=>{
        loadData()
    },[])

    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'manager/dashboard/userstats/'+params.uuid,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
              }            
          })
        .then(res=>{
    
    
            //console.log(res.data)
            res.data.results.forEach(item=>{
                item.BarLabel=item.StatName+': '+item.Value+'%'
            })
            setStats(res.data.results)
    
           setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
    
        })          
    }

    const statsList=stats.map(item=>{
        return(
            <tr>
                <td><b>{item.StatName}</b></td>
                <td width="20"></td>
                <td>{item.Value}%</td>
            </tr>
        )
    })

  return (
    <Container fluid>

        <Card style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',border: '1px solid #f0f0f0'}}>

        <Card.Body>
            <Card.Title>
            <div style={{fontFamily:'Arial, Helvetica, sans-serif',color:'#6f6b7d',fontSize:'16px',fontWeight:'bold'}}>
            User Stats
            </div>

            </Card.Title>
            <Card.Text>
                {/* <table>
                    {statsList}
                </table> */}
<div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer width="100%" height="100%">
            <BarChart

                data={stats}
                layout="vertical"
                margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" domain={[0,100]} tickFormatter={(Value) => `${Value}%`}/>
                <YAxis type="category"  dataKey="StatName" hide={true}/>

                <Bar dataKey="Value" fill="#82ca9d" barSize={25}>
                {/* LabelList is used to add text inside the bars */}
                <LabelList dataKey="BarLabel" position="inside" fill="white" />
                </Bar>
            </BarChart>
            </ResponsiveContainer>
            </div>
            </Card.Text>

        </Card.Body>
        </Card>

    </Container>
  )
}
