import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import {Alert,Container,Form,Button,Card,Navbar,NavDropdown,Nav,Table,Modal,Row,Col,Badge} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faVideo } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';

import VideoPlayer from 'react-video-js-player';

export default function UserActivities(params) {
  const [challenges,setChallenges]=useState([])
  const [isLoading,setIsLoading]=useState(false)
  const [show,setShow]=useState(false)
  const [fileID,setFileID]=useState('')
  const [title,setTitle]=useState('')
  const [descr,setDescr]=useState('')

  const columns = [

    {
      name: '',
      selector: row => <Button variant="success" onClick={()=>handleShow(row.FileID,row.Title,row.Descr)}><FontAwesomeIcon icon={faVideo}/></Button>,
      sortable: true,
      maxWidth:'5px'
  }, 
    {
        name: 'Challenge Title',
        selector: (row,index) =>  row.Title,
        sortable: true
    },
  
    {
      name: 'Points',
      selector: (row,index) =>  row.Points,
      sortable: true,
      maxWidth:'5px'
  },
    {
      name: 'End Date',
      selector: (row,index) =>  moment(row.DateTo).format('DD/MM/YYYY'),
      sortable: true,
      maxWidth:'30px'
    },

  ]


  useEffect(()=>{
    loadData()
},[])


const handleClose=()=>{
  
  setShow(false)
}

const handleShow=(fid,t,d)=>{
  setTitle(t)
  setDescr(d)
  setFileID(fid)
  setShow(true)
}


const loadData=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'manager/dashboard/userchallenges/'+params.uuid,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
          }            
      })
    .then(res=>{


        //console.log(res.data)
        setChallenges(res.data.results)

       setIsLoading(false)
    }).catch(err=>{
        setIsLoading(false)

    })          
}



  return (
    <Container fluid>

        <Card style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',border: '1px solid #f0f0f0'}}>

        <Card.Body>
            <Card.Title>
            <div style={{fontFamily:'Arial, Helvetica, sans-serif',color:'#6f6b7d',fontSize:'16px',fontWeight:'bold'}}>
            User Challenges
            </div>

            </Card.Title>
            <Card.Text>

            <DataTable
              columns={columns}
              data={challenges}
              highlightOnHover
              //expandableRows
              //expandableRowsComponent={ExpandedComponent}
              //pagination
            /> 
            </Card.Text>

        </Card.Body>
        </Card>


        <Modal show={show} onHide={handleClose} backdrop="static" size="lg">
                <Modal.Header closeButton>
                <Modal.Title>
                  {title}
                </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                  <p align="center">
                    
                    <div
                    dangerouslySetInnerHTML={{ __html: descr.replace(/\n/g, '<br>') }}
                    />
                    <hr/>



            <VideoPlayer
                    controls={true}
                    src={`${process.env.REACT_APP_VIDEO_BASE_URL}${fileID}.smil/playlist.m3u8`}
                    poster={process.env.REACT_APP_VIDEOSERVER_API+''+fileID+'.jpg'}
                    width="720"
                    height="420"
                    // onReady={this.onPlayerReady.bind(this)}
                    // onPlay={this.onVideoPlay.bind(this)}
                    // onPause={this.onVideoPause.bind(this)}
                    // onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                    // onSeeking={this.onVideoSeeking.bind(this)}
                    // onSeeked={this.onVideoSeeked.bind(this)}
                    // onEnd={this.onVideoEnd.bind(this)}
                />

                  </p>
                </Modal.Body>

            </Modal>   


    </Container>
  )
}
